import { layerClient } from '../get-layer';
import loadSession from './loadSession';
import persistSession, { sessionKey } from './persistSession';

export default function start() {
  layerClient.on('authenticated', () => {
    if (layerClient.isTrustedDevice) {
      persistSession();
    }
  });
  layerClient.on('deauthenticated', () => {
    if (layerClient.isTrustedDevice) {
      localStorage.removeItem(sessionKey);
    }
  });
  layerClient.on('identities:change', () => {
    if (layerClient.isTrustedDevice) {
      if (layerClient.user.metadata.user_status === 'confirmed' || layerClient.user.metadata.user_status === 'anonymous') {
        persistSession();
      }
    }
  });

  layerClient.isTrustedDevice = true;
  const session = loadSession();
  if (session) {
    layerClient.connect(session.userId);
    return session;
  } else {
    layerClient.connect();
  }
}
