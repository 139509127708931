const MessageItemSentMixin = {
  methods: {
    onAfterCreate() {
      const view = this;
      const customData = view.item._messageTypeModel.customData;
      const conversation = this.item.getConversation(false);
      customData.xircles_status = conversation.metadata.xircles_status;
    },
    onRerender: function() {
      // hide date under message
      this.nodes.date.innerHTML = '';

      // check if card type equal to link-card and change icon if true
      const [linkCardItem] = this.getElementsByClassName('layer-message-type-view');
      if(linkCardItem) {
        const isLinkCard = linkCardItem.properties.model.customData.type === 'link_card';
        if (isLinkCard) {
          const icon = linkCardItem.getElementsByTagName('i')[0];
          icon.classList.remove('icon-send');
          icon.classList.add('icon-open-in-browser');
        }
      }
    }
  }
};

export default MessageItemSentMixin;
