import Layer from '@layerhq/web-xdk';

const { Root, Client } = Layer.Core;

const TextModel = Client.getMessageTypeModelClass('TextModel');

class XirclesRelatedQuestionModel extends TextModel {
}

XirclesRelatedQuestionModel.MIMEType = 'application/xircles.related-question+json';
XirclesRelatedQuestionModel.LabelSingular = 'Related Question';
XirclesRelatedQuestionModel.messageRenderer = 'xircles-related-question-message-view';

Root.initClass.apply(XirclesRelatedQuestionModel, [XirclesRelatedQuestionModel, 'XirclesRelatedQuestionModel']);
Client.registerMessageTypeModelClass(XirclesRelatedQuestionModel, 'XirclesRelatedQuestionModel');

export default XirclesRelatedQuestionModel;
