import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesMatchedQuestionsModel extends MessageTypeModel {
  generateParts(callback) {
    const that = this;

    const body = this.initBodyWithMetadata([
      'text',
    ]);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });

    if (this.questions.length === 0) {
      callback([this.part]);
    } else {
      const parts = [this.part];
      this.questions.forEach((question, i) => {
        that.addChildModel(question, 'questions', (newParts) => {
          newParts.forEach((p) => parts.push(p));
          if (i === this.questions.length - 1) {
            callback(parts);
          }
        });
      });
    }
  }

  parseModelChildParts({ changes, isEdit }) {
    super.parseModelChildParts({ changes, isEdit });

    this.questions = this.getModelsByRole('questions');
  }
}

XirclesMatchedQuestionsModel.prototype.text = '';
XirclesMatchedQuestionsModel.prototype.questions = null;

XirclesMatchedQuestionsModel.MIMEType = 'application/xircles.matched-questions+json';
XirclesMatchedQuestionsModel.SummaryTemplate = '${text}'; // eslint-disable-line no-template-curly-in-string
XirclesMatchedQuestionsModel.LabelSingular = 'Matched Questions';
XirclesMatchedQuestionsModel.messageRenderer = 'xircles-matched-questions-message-view';

Root.initClass.apply(XirclesMatchedQuestionsModel, [XirclesMatchedQuestionsModel, 'XirclesMatchedQuestionsModel']);
Client.registerMessageTypeModelClass(XirclesMatchedQuestionsModel, 'XirclesMatchedQuestionsModel');

export default XirclesMatchedQuestionsModel;
