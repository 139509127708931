import Layer from '@layerhq/web-xdk';

import './related-question-message-model';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const processText = Layer.UI.handlers.text.processText;
const Widths = Layer.UI.Constants.WIDTH;

registerComponent('xircles-related-question-message-view', {
  mixins: [MessageViewMixin],
  properties: {
    widthType: {
      get: function get() {
        return this.parentComponent.isShowingMetadata ? Widths.FLEX : Widths.ANY;
      }
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container'
    }
  },
  methods: {
    onAfterCreate() {
      this.innerHTML = processText(this.model.text);
    },
    onRerender() {
      this.innerHTML = processText(this.model.text);
    }
  }
});