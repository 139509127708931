import Layer from '@layerhq/web-xdk';

const { Root, MessagePart, Client } = Layer.Core;

const FileModel = Client.getMessageTypeModelClass('FileModel');

class XirclesFileModel extends FileModel {

  generateParts(callback) {
    const source = this.source;

    if (source) {
      if (!this.title && source.name) this.title = source.name;
      if (!this.size) this.size = source.size;
      if (!this.mimeType) this.mimeType = source.type;
      this.size = source.size;
    }

    const body = this.initBodyWithMetadata([
      'sourceUrl',
      'author',
      'size',
      'title',
      'mimeType',
      'matchedTags',
      'score',
      'onlyForHcp',
    ]);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });

    if (source) {
      this.source = new MessagePart(this.source);
      this.addChildPart(this.source, 'source');
      this.childParts.push(this.source);
    }

    callback(this.source ? [this.part, this.source] : [this.part]);
  }
}

XirclesFileModel.prototype.source = null;
XirclesFileModel.prototype.sourceUrl = '';
XirclesFileModel.prototype.author = '';
XirclesFileModel.prototype.title = '';
XirclesFileModel.prototype.size = '';
XirclesFileModel.prototype.mimeType = '';
XirclesFileModel.prototype.matchedTags = '';
XirclesFileModel.prototype.score = 0;
XirclesFileModel.prototype.onlyForHcp = false;

XirclesFileModel.LabelSingular = 'File';
XirclesFileModel.LabelPlural = 'Files';
XirclesFileModel.SummaryTemplate = '';
XirclesFileModel.defaultAction = 'open-file';

XirclesFileModel.MIMEType = 'application/vnd.layer.file+json';
XirclesFileModel.messageRenderer = 'layer-file-message-view';

Root.initClass.apply(XirclesFileModel, [XirclesFileModel, 'FileModel']);
Client.registerMessageTypeModelClass(XirclesFileModel, 'FileModel');

export default XirclesFileModel;
