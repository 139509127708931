import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesButtonLinkModel extends MessageTypeModel {
    generateParts(callback) {
        const body = this.initBodyWithMetadata([
            'title',
            'url',
            'align'
        ]);

        this.part = new MessagePart({
            mimeType: this.constructor.MIMEType,
            body: JSON.stringify(body),
        });
        callback([this.part]);
    }
}

XirclesButtonLinkModel.prototype.title = '';
XirclesButtonLinkModel.prototype.url = '';
XirclesButtonLinkModel.prototype.align = 'right';

XirclesButtonLinkModel.MIMEType = 'application/xircles.button-link+json';
XirclesButtonLinkModel.LabelSingular = 'Button Link';
XirclesButtonLinkModel.messageRenderer = 'xircles-button-link-message-view';

Root.initClass.apply(XirclesButtonLinkModel, [XirclesButtonLinkModel, 'XirclesButtonLinkModel']);
Client.registerMessageTypeModelClass(XirclesButtonLinkModel, 'XirclesButtonLinkModel');

export default XirclesButtonLinkModel;
