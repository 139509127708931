import { Layer } from '../../get-layer'

import productLogo from '../../images/xillocentLogoSmall.png';
const { Root, MessagePart, MessageTypeModel, Client } = Layer.Core;
class XircleOverviewModel extends MessageTypeModel {
  generateParts(callback) {
    const body = this.initBodyWithMetadata([
      'title',
      'imageUrl',
      'unreadMessageCount',
      'conversationId',
      'unreadProjectRequestCount',
      'unreadQuestionResponseCount'
    ]);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });
    callback([this.part]);
  }

  getOneLineSummary() {
    return `New in ${this.title}`;
  }
}

XircleOverviewModel.prototype.title = '';
XircleOverviewModel.prototype.imageUrl = productLogo;
XircleOverviewModel.prototype.conversationId = '';
XircleOverviewModel.prototype.unreadMessageCount = 0;
XircleOverviewModel.prototype.unreadQuestionResponseCount = 0;
XircleOverviewModel.prototype.unreadProjectRequestCount = 0;

XircleOverviewModel.MIMEType = 'application/xircles.xircle.overview+json';
XircleOverviewModel.messageRenderer = 'xircles-xircle-overview-message-view';

Root.initClass.apply(XircleOverviewModel, [XircleOverviewModel, 'XircleOverviewModel']);
Client.registerMessageTypeModelClass(XircleOverviewModel, 'XircleOverviewModel');

export default XircleOverviewModel;