import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesControlModel extends MessageTypeModel {
    generateParts(callback) {
        const body = this.initBodyWithMetadata([
            'eventName',
            'serviceName',
            'data'
        ]);

        this.part = new MessagePart({
            mimeType: this.constructor.MIMEType,
            body: JSON.stringify(body),
        });
        callback([this.part]);
    }
}

XirclesControlModel.prototype.eventName = '';
XirclesControlModel.prototype.serviceName = '';
XirclesControlModel.prototype.data = {};

XirclesControlModel.MIMEType = 'application/xircles.control+json';
XirclesControlModel.LabelSingular = 'Control Message';
XirclesControlModel.messageRenderer = 'xircles-control-message-view';

Root.initClass.apply(XirclesControlModel, [XirclesControlModel, 'XirclesControlModel']);
Client.registerMessageTypeModelClass(XirclesControlModel, 'XirclesControlModel');

export default XirclesControlModel;
