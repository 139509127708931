import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesRelatedQuestionsModel extends MessageTypeModel {
  generateParts(callback) {
    const that = this;

    const body = this.initBodyWithMetadata([
      'text',
    ]);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });

    if (this.questions.length === 0) {
      callback([this.part]);
    } else {
      const parts = [this.part];
      this.questions.forEach((question, i) => {
        that.addChildModel(question, 'questions', (newParts) => {
          newParts.forEach((p) => parts.push(p));
          if (i === this.questions.length - 1) {
            callback(parts);
          }
        });
      });
    }
  }

  parseModelChildParts({ changes, isEdit }) {
    super.parseModelChildParts({ changes, isEdit });

    this.questions = this.getModelsByRole('questions');
  }
}

XirclesRelatedQuestionsModel.prototype.text = '';
XirclesRelatedQuestionsModel.prototype.questions = null;

XirclesRelatedQuestionsModel.MIMEType = 'application/xircles.related-questions+json';
XirclesRelatedQuestionsModel.SummaryTemplate = '${text}'; // eslint-disable-line no-template-curly-in-string
XirclesRelatedQuestionsModel.LabelSingular = 'Related Questions';
XirclesRelatedQuestionsModel.messageRenderer = 'xircles-related-questions-message-view';

Root.initClass.apply(XirclesRelatedQuestionsModel, [XirclesRelatedQuestionsModel, 'XirclesRelatedQuestionsModel']);
Client.registerMessageTypeModelClass(XirclesRelatedQuestionsModel, 'XirclesRelatedQuestionsModel');

export default XirclesRelatedQuestionsModel;
