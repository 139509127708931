import _ from 'lodash';

const TextMessageViewMixin = {
  methods: {
    onAfterCreate: function () {
      const view = this;
      const modelTypeName = view.model.constructor.name;

      if (modelTypeName === 'XirclesQuestionModel') {
        this.classList.add('xircles-question-message');
      } else {
        const text = view.model.text.toLowerCase().trim();

        if (['hidden', 'start typing', 'end typing'].indexOf(text) > -1 || _.startsWith(text, 'cmd:')) {
          view.closest('.layer-message-item').classList.add('xircles-mode-hidden');
          view.closest('.layer-list-item').classList.add('xircles-hide');
        } else {
          if (view.properties.model.customData.showBack) {
            const conversationId = view.messageViewer.message.conversationId;
            const div = document.createElement('div');
            view.closest('.layer-list-item').appendChild(div);
            view.nodes.backButton = document.createElement('button');
            view.nodes.backButton.classList.add('xircles-icons', 'icon-up', 'xircles-hide');
            view.nodes.backButton.innerHTML = 'Back';
            div.appendChild(view.nodes.backButton);
            view.nodes.backButton.addEventListener('click', () => {
              view.nodes.backButton.classList.add('xircles-hide');
              view.trigger('xircles-choice-send', {conversationId, text: 'back', id: 'back'});
            });
          }
        }

      }
    }
  }
};

export default TextMessageViewMixin;
