import './style.scss'

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from '@material/react-dialog';

export default Dialog;
export {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
};
