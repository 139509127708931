import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesContentCardModelSwipe extends MessageTypeModel {
  generateParts(callback) {
    const body = this.initBodyWithMetadata([
      'header',
      'url',
      'text',
      'linkText',
      'matchedTags',
      'score',
      'onlyForHcp',
    ]);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });
    callback([this.part]);
  }
}

XirclesContentCardModelSwipe.prototype.header = '';
XirclesContentCardModelSwipe.prototype.url = '';
XirclesContentCardModelSwipe.prototype.text = '';
XirclesContentCardModelSwipe.prototype.linkText = '';
XirclesContentCardModelSwipe.prototype.matchedTags = '';
XirclesContentCardModelSwipe.prototype.score = 0;
XirclesContentCardModelSwipe.prototype.onlyForHcp = false;

XirclesContentCardModelSwipe.MIMEType = 'application/xircles.content-card-swipe+json';
XirclesContentCardModelSwipe.LabelSingular = 'Content Card Swipe';
XirclesContentCardModelSwipe.messageRenderer = 'xircles-content-card-message-view-swipe';

Root.initClass.apply(XirclesContentCardModelSwipe, [XirclesContentCardModelSwipe, 'XirclesContentCardModelSwipe']);
Client.registerMessageTypeModelClass(XirclesContentCardModelSwipe, 'XirclesContentCardModelSwipe');

export default XirclesContentCardModelSwipe;
