import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesTextLinkModel extends MessageTypeModel {
  generateParts(callback) {
    const body = this.initBodyWithMetadata([
      'title',
      'url',
    ]);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });
    callback([this.part]);
  }
}

XirclesTextLinkModel.prototype.title = '';
XirclesTextLinkModel.prototype.url = '';

XirclesTextLinkModel.MIMEType = 'application/xircles.text-link+json';
XirclesTextLinkModel.LabelSingular = 'Text Link';
XirclesTextLinkModel.messageRenderer = 'xircles-text-link-message-view';

Root.initClass.apply(XirclesTextLinkModel, [XirclesTextLinkModel, 'XirclesTextLinkModel']);
Client.registerMessageTypeModelClass(XirclesTextLinkModel, 'XirclesTextLinkModel');

export default XirclesTextLinkModel;
