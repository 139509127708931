import Layer from '@layerhq/web-xdk';

const { Root, Client } = Layer.Core;

const ChoiceModel = Client.getMessageTypeModelClass('ChoiceModel');

class XirclesCheckboxModel extends ChoiceModel {
  // messageRenderer hardcoded in ChoiceModel, so need to override here
  __getCurrentMessageRenderer() {
    switch (this.type.toLowerCase()) {
      case 'standard':
        return XirclesCheckboxModel.messageRenderer;
      default:
        return super.__getCurrentMessageRenderer();
    }
  }
}

XirclesCheckboxModel.MIMEType = 'application/xircles.checkbox+json';
XirclesCheckboxModel.messageRenderer = 'xircles-checkbox-message-view';

Root.initClass(XirclesCheckboxModel, [XirclesCheckboxModel, 'XirclesCheckboxModel']);
Client.registerMessageTypeModelClass(XirclesCheckboxModel, 'XirclesCheckboxModel');

export default XirclesCheckboxModel;
