import Layer from '@layerhq/web-xdk';

const { Root, Client } = Layer.Core;

const TextModel = Client.getMessageTypeModelClass('TextModel');

class XirclesConfirmChoiceModel extends TextModel {
}

XirclesConfirmChoiceModel.MIMEType = 'application/xircles.confirm-choice+json';
XirclesConfirmChoiceModel.LabelSingular = 'Confirm Choice';
XirclesConfirmChoiceModel.messageRenderer = 'layer-text-message-view';

Root.initClass.apply(XirclesConfirmChoiceModel, [XirclesConfirmChoiceModel, 'XirclesConfirmChoiceModel']);
Client.registerMessageTypeModelClass(XirclesConfirmChoiceModel, 'XirclesConfirmChoiceModel');

export default XirclesConfirmChoiceModel;
