import Layer from '@layerhq/web-xdk';

const { Root, Client } = Layer.Core;

const ChoiceModel = Client.getMessageTypeModelClass('ChoiceModel');

class XirclesDocCheckModel extends ChoiceModel {
  // messageRenderer hardcoded in ChoiceModel, so need to override here
  __getCurrentMessageRenderer() {
    switch (this.type.toLowerCase()) {
      case 'standard':
        return XirclesDocCheckModel.messageRenderer;
      default:
        return super.__getCurrentMessageRenderer();
    }
  }
}

XirclesDocCheckModel.MIMEType = 'application/xircles.doccheck+json';
XirclesDocCheckModel.messageRenderer = 'xircles-doccheck-message-view';

Root.initClass(XirclesDocCheckModel, [XirclesDocCheckModel, 'XirclesDocCheckModel']);
Client.registerMessageTypeModelClass(XirclesDocCheckModel, 'XirclesDocCheckModel');

export default XirclesDocCheckModel;
